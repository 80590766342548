
.sectionn{
	/* font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-size: 15px; */
 line-height: 1.7; 
	/* color: #ff4a4a;
	background-color: #da6b57;
  */
    background-position: right;
    background-size: 100%;
    
    background-image:url('/src/Components/images/Collage\ example.jfif') ;
  
    background-repeat: no-repeat;
   
	overflow-x: hidden;
height: 100vh;
    
} 
@media (max-width: 575px) {
  .sectionn {
      background-position: center; /* Center the background image */
      background-size: cover; /* Make the background image cover the section */
      background-attachment: scroll; /* Allow background image to scroll with content */
      height: 100vh; /* Make sure the section takes full viewport height */
  }
}

/* For small screens (phones in landscape mode) */
@media (min-width: 576px) and (max-width: 767px) {
  .sectionn {
      background-position: center; /* Center the background image */
      background-size: cover; /* Make the background image cover the section */
      background-attachment: scroll; /* Allow background image to scroll with content */
      height: 100vh; /* Make sure the section takes full viewport height */
  }
}

/* For medium screens (tablets in portrait mode) */
@media (min-width: 768px) and (max-width: 991px) {
  .sectionn {
      background-position: center; /* Center the background image */
      background-size: cover; /* Make the background image cover the section */
      background-attachment: scroll; /* Allow background image to scroll with content */
      height: 100vh; /* Make sure the section takes full viewport height */
  }
}

a {
	cursor: pointer;
  transition: all 100ms linear;
}
a:hover {
	text-decoration: none;
}
.link {
  color: #c4c3ca;
}
.link:hover {
  color: #fd671a;
}
p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
}
h4 {
  font-weight: 800;
  color:#000000;
  font-family: Georgia, 'Times New Roman', Times, serif;
  box-shadow: #000000;
}
h6 span{
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 900;
  background-color: #f5cc19;
  color: b;
 
}
.header {
  /* position: relative;  /* Set positioning context */
  /* z-index: 1;  /* Ensure header is on top */
  border: 1px solid #00000030; /* Add border style */
  background-color: rgb(255, 232, 127);
text-align: center;
  align-items: center;
  margin-right: 0px;
  font-size: 40px;
 
  /* margin-left: 30px; */
padding:20px;
  height: 75px;
}
.section{
  position: relative;
  width: 100%;
  display: block;
} 
 /* .full-height{
  min-height: 100vh;
}  */
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
  position: absolute;

   /* left: -9999px;  */
}
.checkbox:checked + label,
.checkbox:not(:checked) + label{
  position: relative;
  display: block;
  text-align: center;
  width: 70px;
  height: 16px;
  border-radius: 8px;
  top: -13px;
  padding: 0;
  margin: 10px auto;
  cursor: pointer;
  background-color: white;
}
.checkbox:checked + label:before,
.checkbox:not(:checked) + label:before{
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color:black ;
  background-color:  #f5cc19;
 
  content: 'L';
  z-index: 20;
  top: -15px;
  left: -10px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  transition: all 0.5s ease;
}
.checkbox:checked + label:before {
  transform: translateX(44px) rotate(-360deg);
  content: 'S';
}


.card-3d-wrap {
  position: relative;
  width: 440px;
  max-width: 100%;
  height: 400px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 800px;
  margin-top: 60px;
}
.card-3d-wrapper {
  width: 90%;
  height: 100%;
  position:absolute;    
  top: 0;
  left: 0;  
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 600ms ease-out; 
}
.card-front, .card-back {
  width: 100%;
  height: 140%;
 
  /* box-shadow: 5px 4px 5px 0px #f5cc19; */
   /* background-image: url('/src/Components/images/Absolute\ Black.png');  */
   background-color: rgb(255, 255, 239);
  background-position: bottom center;
  background-repeat: repeat;
  background-size: 100%;
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.card-back {
  transform: rotateY(180deg);
  height: 140%;
}
.checkbox:checked ~ .card-3d-wrap .card-3d-wrapper {
  transform: rotateY(180deg);
}
.center-wrap{
  position: absolute;
  width: 100%;
  padding: 0 45px;
  top: 47%;
  left: 0;
  transform: translate3d(0, -40%, 35px) perspective(100px);
  z-index: 20;
  display: block;
}


.form-group{ 
  position: relative;
  display: block;
    margin: 0;
    padding: 0;
}
.form-style {
  padding: 13px 20px;
  padding-left: 55px;
  height: 48px;
  width: 100%;
  font-weight: 500px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  outline: none;
  color: #000000;
  background-color: transparent;
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0.9px 0.5px  0.5px 0.5px #f5cc19;
}
.form-style:focus,
.form-style:active {
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: 0.9px 0.5px  0.5px 0.5px #000000;
}
.input-icon {
  position: absolute;
  top: 0;
  left: 18px;
  height: 48px;
  font-size: 24px;
  line-height: 48px;
  text-align: left;
  color: #000000;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.form-group input:-ms-input-placeholder  {
  color: #000000;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input::-moz-placeholder  {
  color: #000000;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input:-moz-placeholder  {
  color:#000000;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input::-webkit-input-placeholder  {
  color: #000000;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
/* .form-group input:focus:-ms-input-placeholder  {
  opacity: 0;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input:focus::-moz-placeholder  {
  opacity: 0;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input:focus:-moz-placeholder  {
  opacity: 0;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.form-group input:focus::-webkit-input-placeholder  {
  opacity: 0;
  -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
} */

.btn{  
  border-radius: 4px;
  height: 44px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition : all 200ms linear;
  transition: all 200ms linear;
  padding: 0 30px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  background-color:#f5cc19;
  color: black;
  /* box-shadow: 0 8px 24px 0 rgb(0, 0, 0); */
}
.btn:active,
.btn:focus{  
  background-color: #f4c507;
  color: black;
  /* box-shadow: 0 8px 24px 0 rgba(16,39,112,.2); */
}
.btn:hover{  
  background-color: #f4c507;
  color:black;
  box-shadow: 0 8px 24px 0 rgba(17, 17, 17, 0);
}



/* 
.logo {
	position: absolute;
	top: 30px;
	right: 30px;
	display: block;
	z-index: 100;
	transition: all 250ms linear;
}
.logo img {
	height: 26px;
	width: auto;
	display: block;
}  */