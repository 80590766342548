/* 
:root {
    --primary-color: #000000;
    --bg-color: #f4f7fa;
    --text-color: #000000;
    --sidebar-bg: #df8c62;
    --sidebar-hover: #ffffff;
  }
  
  
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: #333;
  color: #fff;
  padding-top: 50px; 
}

.sidebar.closed {
  width: 80px; 
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #555;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul a {
  display: block;
  padding: 10px 20px;
 
  text-decoration: none;
}




  .sidebar {
    height: 100%;
    width: 250px;
    position: fixed;
    color: #000000;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--sidebar-bg);
    overflow-x: hidden;
    transition: 0.3s;
    box-shadow: 0 0 15px black;
    white-space: nowrap;
  }
  
   .sidebar-header {
    color: #000000;
    height: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
  } 
  
 .sidebar-header h3 {
    margin: 0;
    font-size: 1.2em;
    color: black;
  }
   
 .toggle-btn {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s;
  } 
  

  
  .sidebar a {
    padding: 15px 25px;
    text-decoration: none;
    font-size: 16px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    transition: 0.2s;
  } 
  .toggle-btn:hover {
    color: var(--primary-color);
  }
  .sidebar a:hover {
    background-color: var(--sidebar-hover);
    color: var(--primary-color);
  }
  
  .sidebar a i {
    min-width: 30px;
    font-size: 20px;
  }
  

  
  .sidebar.closed {
    width: 70px;
  }
  
  .sidebar.closed .sidebar-header h3 {
    display: none;
  }
  
  .sidebar.closed a span {
    display: none;
  }
  
  .sidebar.closed ~ #main {
    margin-left: -100px;
    
  }
.sidebar.open{
  position: fixed;
}


  @media screen and (max-width: 768px) {

    .sidebar {
      width: 70px;
    }
  
    .sidebar .sidebar-header h3 {
      display: none;
    }
  
    .sidebar a span {
      display: none;
    }
   
    #main {
      margin-left: 90px;
    } 
  
    .sidebar.open {
      width: 250px;
    }
  
    .sidebar.open .sidebar-header h3 {
      display: block;
    }
  
    .sidebar.open a span {
      display: inline;
    }
  
     .sidebar.open ~ #main {
      margin-left: 240px;
    } 
  }
  



  .sidebar a {
    padding: 15px 25px;
    text-decoration: none;
    font-size: 16px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    position: relative; 
    transition: 0.2s;
  }
  

  
  .sidebar a i {
    min-width: 30px;
    font-size: 20px;
    margin-right: 10px;
  }
  


.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--sidebar-bg);
    min-width: 200px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 10px 0;
  }
  
  .sidebar a:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu li {
    list-style-type: none;
    padding: 10px 20px;
  }
  
  .dropdown-menu li a {
    text-decoration: none;
    color: var(--text-color);
    transition: 0.2s;
  }
  
  .dropdown-menu li a:hover {
    color: var(--primary-color);
  }


  #main {
    transition: margin-left 0.3s;
    padding: 20px;
    margin-left: 250px;
  } */

  :root {
    --primary-color: #000000;
    --bg-color: white-space;
    --text-color: #000000;
    --sidebar-bg: #ffffff;
    --sidebar-hover: #fbe867;
}
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--bg-color);
    color: var(--text-color);
}
.sidebar {
    height: 100%;
   
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--sidebar-bg);
    overflow-x: hidden;
    transition: 0.3s;
    box-shadow: 0 0 15px #f5cc19;
    white-space: nowrap;
}
.sidebar-header {
    padding: 10px;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}
.sidebar-header h3 {
    margin: 0;
    font-size: 1.2em;
    color: var(--primary-color);
}
.toggle-btn {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s;
}
.toggle-btn:hover {
    color: var(--primary-color);
}
.sidebar a {
    padding: 15px 25px;

    text-decoration: none;
    font-size: 16px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    transition: 0.2s;
}
.sidebar a:hover {
    background-color: var(--sidebar-hover);
    border-radius: 10px;
  
    color: var(--primary-color);
    
}
.sidebar a i {
    min-width: 30px;
    font-size: 20px;
}
#main {
    transition: margin-left .3s;
    padding: 20px;
    margin-left: 250px;
}
.sidebar.closed {
    width: 70px;
}
.sidebar.closed .sidebar-header h3 {
    display: none;
}
.sidebar.closed a span {
    display: none;
}
.sidebar.closed ~ #main {
    margin-left: 70px;
}
@media screen and (max-width: 768px) {
    .sidebar {
        width: 70px;
    }
    .sidebar .sidebar-header h3 {
        display: none;
    }
    .sidebar a span {
        display: none;
    }
    #main {
        margin-left: 70px;
    }
    .sidebar.open {
        width: 250px;
    }
    .sidebar.open .sidebar-header h3 {
        display: block;
    }
    .sidebar.open a span {
        display: inline;
    }
    .sidebar.open ~ #main {
        margin-left: 250px;
    }
}




.dropdown-menu {
  display: none;
  position: absolute;
  top: 20%;
  left: 0%;
  background-color: var(--sidebar-bg);
  min-width: 200px;
  z-index: 1;

}

.sidebar a:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  list-style-type: none;
  padding: 10px 20px;
}

.dropdown-menu li a {
  text-decoration: none;
  color: var(--text-color);
  transition: 0.2s;
}

.dropdown-menu li a:hover {
  color: var(--primary-color);
}






/*****search bar********/


.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color:#ffffff;
  padding-right: 40px;
  color:rgb(255, 255, 255);
}
.input-search::placeholder{
  color:#f5cc19;
  font-size: 18px;
  letter-spacing: 3px;
  font-weight: 100;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:#f5cc19 ;
  background-color:transparent;
  pointer-events: painted;  
}
.btn-search:focus ~ .input-search{
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #f5cc19;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #f5cc19;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

/********end**********/
/* styles.css */
/* .search-box {
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-search {
  border: none;
  background: transparent;
}

.input-search {
  margin-left: 10px;
  flex: 1;
} */

/* @media (max-width: 767.98px) {
  .input-search {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .input-search {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .input-search {
    font-size: 18px;
  }
} */
/* 
@media (min-width: 702px) {
  .text-right {
   margin-left: 50px;
   
  }
} */


