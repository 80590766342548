.tick-icon {
    font-size: 1.5em;
    display: inline-block;
    align-items: center;
    margin-right: 5px;
  }
  
  .tick-icon.accept {
    color: green;
  }
  
  .tick-icon.reject {
    color: red;
  }
  

  /* Center buttons horizontally */
.center-buttons {
  display: flex;
  justify-content: center;
  width: 120px;
  gap: 8px; /* Adjust spacing between buttons */
}

/* Optionally, you can also add custom styling for buttons */
.tick-icon {
  font-size: 16px; /* Adjust as necessary */
}


.comment-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.comment-bubble {
  border-radius: 10px;
  padding: 10px;
  background-color: #e1ffc7; /* Light green for comments */
  margin-bottom: 10px;
  max-width: 80%;
}

.reply-bubble {
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff; /* White for replies */
  margin-bottom: 10px;
  border-left: 2px solid #ddd;
}

.reply-list {
  margin-left: 20px;
}
